<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Credit report billing tool</h3>
      </div>
      <button class="btn bg-green" @click="modals.uploadCsv = true">
        Upload CSV
      </button>
    </div>
    <div class="page-content p-normal">
      <div class="search-wrapper">
        <img src="@/assets/icons/icon-search.svg" alt="Icon search">
        <input type="text" placeholder="Search">
      </div>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Agent</th>
              <th>Charges</th>
              <th>Last charged</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nathan Kowarsky</td>
              <td>$0.00</td>
              <td>Oct, 05 2020 <b>($705.00)</b></td>
              <td>
                <button class="btn btn-outline-dark" @click="modals.chargeCredit = true">
                  Charge
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <upload-csv-modal v-if="modals.uploadCsv === true" @close="modals.uploadCsv = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <charge-credit-modal v-if="modals.chargeCredit === true" @close="modals.chargeCredit = false" />
    </transition>

  </div>
</template>

<script>
import UploadCsvModal from "./modals/UploadCsvModal";
import ChargeCreditModal from "./modals/ChargeCreditModal";
export default {
  name: 'CreditReport',
  components: {ChargeCreditModal, UploadCsvModal},
  data() {
    return {
      modals: {
        uploadCsv: false,
        chargeCredit: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
