<template>
  <div class="modal modal-small-center box-shadow-light border-small">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Upload CSV
        </h3>
        <img alt="Close" class="cursor-pointer" src="@/assets/icons/icon-remove.svg" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <file-field :id="1" :multiple="true" :files="files" class="mb-1" />
        <div class="d-flex mt-4 mb-3">
          <button class="btn bg-green" @click="updateCSV">
            <span v-if="ui.saving === true"
                  class="spinner-border spinner-border-sm text-light"
                  role="status" aria-hidden="true">
            </span>
            <span class="text-end" v-else>
              Upload
            </span>
          </button>
          <button class="btn btn-cancel ms-2" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";
export default {
  name: 'UploadCsvModal',
  components: {FileField},
  data() {
    return {
      files: [],
      ui: {
        saving: false
      }
    }
  },
  methods: {
    updateCSV() {
      this.ui.saving = true;
      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  position: relative!important;
}

.modal-body {
  padding: 0 30px;

}
</style>
