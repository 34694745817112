<template>
  <div class="modal modal-small-center box-shadow-light border-small">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Charge Account
        </h3>
        <img alt="Close" class="cursor-pointer" src="@/assets/icons/icon-remove.svg" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="text-center mb-5" v-if="ui.loading === true">
          <div class="spinner-border mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container mb-3 pb-3">
            <div class="d-flex align-items-center">
              <v-avatar :username="'John Smith'" :size="42" rounded />
              <h6 class="ms-3 mb-0">
                John Smith
              </h6>
            </div>
          </div>
          <div class="modal-body-container pb-0 border-0">
            <div class="form-group full-width">
              <label for="fieldTotalPrice">
                Total Price:
              </label>
              <input type="text" class="form-control" id="fieldTotalPrice" v-model="totalPrice">
            </div>
          </div>
          <div class="d-flex mt-3 mb-3">
            <button class="btn bg-green" @click="chargeCredit">
            <span v-if="ui.saving === true"
                  class="spinner-border spinner-border-sm text-light"
                  role="status" aria-hidden="true">
            </span>
              <span class="text-end" v-else>
              Charge
            </span>
            </button>
            <button class="btn btn-cancel ms-2" @click="$emit('close')">
              Cancel
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ChargeCreditModal',
  data() {
    return {
      ui: {
        saving: false,
        loading: false
      },
      totalPrice: ''
    }
  },
  methods: {
    chargeCredit() {
      this.saving = true;

      setTimeout(() => {
        this.saving = false;
      }, 1000)
    },
    getData() {
      this.ui.loading = true;
      console.log('test')
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  position: relative!important;
}

.modal-body {
  padding: 0 30px;
}
</style>
